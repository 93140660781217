import styled from 'styled-components';

export const P = styled.div`
  font-family: 'Source Code Pro', monospace;
  margin-top: 10px;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: 520px) {
    margin-top: 10px;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const Quote = styled.div`
  padding: 40px;
  border-radius: 20px;

  @media (max-width: 520px) {
    margin-top: 5px;
    font-size: 14px;
    line-height: 16px;
  }
`;
