import { configureStore, Action, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import VulpinReducer from './Slices/Vulpin';
import { IVulpinState } from './Types';

export type WizardThunk = ThunkAction<void, IVulpinState, unknown, Action<string>>;
const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {},
  }),
];

const rootReducer = combineReducers({
  WizardStore: VulpinReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

// Turn redux dev tools on for development
const store = configureStore({
  reducer: {
    VulpinStore: VulpinReducer,
  },
  middleware: middleware,
  devTools: true,
});

export default store;
