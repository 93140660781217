import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Template } from '../../Components/Templates';
import { Green, Bold, Blue, Cyan, Magenta, Orange, Comment } from '../../Components/Templates/Vulpine/colors';
import { getQuote, VulpinSelector } from '../../Redux/Slices/Vulpin';
import { P, Quote } from './styles';

const Welcome: React.FC = () => {
  const dispatch = useDispatch();
  const { quote } = useSelector(VulpinSelector);
  useEffect(() => {
    dispatch(getQuote());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Template>
      <Quote>
        <P>
          <Comment>&#x2f;&#x2f; helpers.tsx</Comment>
          <br />
          <Green>export</Green> <Bold>const</Bold> <Magenta>Quote</Magenta> <Green>=</Green> (<Orange>message</Orange>
          <Green>:string</Green>, <Orange>author</Orange> <Green>:string</Green>) =&gt; <Cyan>&#x2035;$&#x7b;</Cyan>
          <Blue>message</Blue>
          <Cyan>&#x7d; -$&#x7b;</Cyan>
          <Blue>author</Blue>
          <Cyan>&#x7d;</Cyan>
          <Cyan>&#x2035;</Cyan>;<br />
          <br />
          <Comment>&#x2f;&#x2f; index.tsx</Comment>
          <br />
          <Green>import</Green> &#x7b; <Blue>Quote</Blue> &#x7d; <Green>from</Green> <Cyan>&#x27;./helpers&#x27;</Cyan>
          <br />
          <br />
          <Magenta>Quote</Magenta>(<Cyan>&#x27;{quote.message}&#x27;</Cyan>, <Cyan>&#x27;{quote.author}&#x27;</Cyan>
          )&#x3b;
        </P>
      </Quote>
    </Template>
  );
};
export default Welcome;
