import styled from 'styled-components';

export const Bold = styled.span`
  color: #000;
  font-weight: 600;
`;

export const Blue = styled.span`
  color: #268bd2;
`;

export const Green = styled.span`
  color: #859900;
`;

export const Cyan = styled.span`
  color: #2aa198;
`;

export const Violet = styled.span`
  color: #6c71c4;
`;

export const Magenta = styled.span`
  color: #d33682;
  font-weight: bold;
`;

export const Red = styled.span`
  color: #dc322f;
`;

export const Orange = styled.span`
  color: #cb4b16;
`;

export const Yellow = styled.span`
  color: #b58900;
`;

export const Comment = styled.span`
  color: #93a1a1;
`;
