import { gql } from '@apollo/client';

export const qGetLastQuote = gql`
  query getLastQuote {
    lastQuote {
      author
      message
    }
  }
`;
