import React from 'react';
import { Header, Title, Content, Body, Wrapper } from './styles';

const Template: React.FC = (props) => {
  return (
    <Content>
      <Header>
        <Wrapper>
          <Title>🦊 The Quick Brown Fox</Title>
        </Wrapper>
      </Header>
      <Wrapper>
        <Body>{props.children}</Body>
      </Wrapper>
    </Content>
  );
};

export default Template;
