import styled, { createGlobalStyle } from 'styled-components';

export const Header = styled.div``;

export const Title = styled.h1`
  text-align: left;
  margin-top: 40px;
  font-family: 'Gochi Hand', cursive;
  font-size: 44px;
  line-height: 40px;
  @media (max-width: 520px) {
    font-size: 24px;
    line-height: 22px;
  }
  color: #000;
`;

export const Content = styled.div`
  font-family: 'Source Code Pro', monospace;
  margin: 0;
  padding: 0px;
`;

export const Body = styled.div`
  font-family: 'Source Code Pro', monospace;
  margin: 0;
  padding: 0px;
`;

export const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    outline: none;
  }

  #root {
  margin: 0 auto;
}

html {
  font-family: "Source Code Pro", monospace;
}

img {
  width: 100%;
  height: 100%;
}

body{
  min-height: 450px;
  height: 100vh;
  margin: 0;
  background: #fdf6e3;
  color: #000;
  font-family: 'Open Sans', sans-serif;
}

`;
