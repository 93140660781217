import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GlobalStyle from './Components/Templates/Vulpine/styles';
import Home from './Pages/Home';

const App: React.FC = () => {
  useEffect(() => {
    ReactGA.initialize('UA-199286698-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <Router>
        <GlobalStyle />
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
