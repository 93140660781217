import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import axios from 'axios';
import { qGetLastQuote } from '../Queries';
import { IVulpinState, IQuote } from '../Types';
import { WizardThunk } from '../store';

const client = new ApolloClient({
  uri: '/query',
  cache: new InMemoryCache(),
});

const initialState: IVulpinState = {
  quote: {
    author: '',
    message: '',
  },
};

const Vulpin = createSlice({
  name: 'Vulpix',
  initialState,
  reducers: {
    setQuote: (state, { payload }: PayloadAction<IQuote>) => {
      state.quote = payload;
    },
  },
});

export const { setQuote } = Vulpin.actions;
export default Vulpin.reducer;
export const VulpinSelector = (state: { VulpinStore: IVulpinState }) => state.VulpinStore;

// API
export const getQuote = (): WizardThunk => {
  return async (dispatch) => {
    const result = await client.query({ query: qGetLastQuote });
    const newQuote: IQuote = {
      author: result.data.lastQuote.author,
      message: result.data.lastQuote.message,
    };
    try {
      // const response = await axios.get(`${NodeServer}/create-customer`);
      dispatch(setQuote(newQuote));
    } catch (error) {
      console.error('SayHi ERROR:', error);
    }
  };
};
